import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import Clock from '../../components/Clock/Clock'
import Navbar from '../../components/Navbar'
import Tables from '../../components/Tables'
import Decr from '../../components/Decr'
import Continue from '../../components/Continue'
import Popup from '../../components/Popup'
import BusinessLunch from '../../components/BusinessLunch'
import moment from 'moment'
import FullMenu from '../../components/FullMenu'
import { MdEdit } from 'react-icons/md'
import Name from '../../components/Name'
import Timer from '../../components/Timer'
import { FiArrowRightCircle } from 'react-icons/fi'

const Index = () => {
  const [isActive, 
        setIsActive] = useState(true);
  const [isActive_, 
        setIsActive_] = useState(false);
    const [blList,
        setBlList] = useState([])
    const [fullMenuList,
        setFullMenuList] = useState([])
    const [openMenu,
        setOpenMenu] = useState(false)
    const [request,
        setRequest] = useState([])
    const [token,
        setToken] = useState(null)
    const [tables,
        setTables] = useState(null)
    const [orderId,
        setOrderId] = useState(null)
    const [isOpenDecr,
        setIsOpenDecr] = useState(null)
    const [isOpenName,
        setIsOpenName] = useState(false)
    const [steps,
        setSteps] = useState({
            isActive: 1,
            guestСount: 1,
            table: null
        })
    const [guests,
        setGuests] = useState('')
        console.log('v.01.04.03')
    const getMenuList = () => { // получаем меню бизнес-ланча
        const date = moment().format('YYYY-MM-DD')
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/restaurant/60325/businessmenu/${date}`)
                .then(response => response.json())
                .then(data => {
                    setBlList(data.data)
                })
        } catch (error) {
            return error
        }
    }

    const getTables = () => { // получаем список столов
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/table/60325`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setTables(data.data)
            })
        } catch (error) {
            return error
        }
    }

    const getMainMenuList = () => { // получаем полное меню
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/restaurant/60325/menu`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setFullMenuList(data.data)
            })
        } catch (error) {
            return error
        }
    }

    async function fetchAuthToken() { // получаем токен
        const url = 'https://api.menu-rest.com/api/v1/auth/phonegetpin';
        const body = JSON.stringify({
            phone: '+78999999999'
        });
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
    
            const data = await response.json();
            fetchAuthToken_();
            return data.token;
        } catch (error) {
            throw error;
        }
    }

    async function fetchAuthToken_() {
        const url = 'https://api.menu-rest.com/api/v1/auth/phonelogin';
        const body = JSON.stringify({
            phone: '+78999999999',
            pin: '12345'
        });
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
    
            const data = await response.json();
            setToken(data.token);
        } catch (error) {
            throw error;
        }
    }

    const handleGetAddDecription = (item) => {
        setIsOpenDecr(item.id)
    }

    const handleCancel = () => { // отменяем заказ
        try { 
            const response = fetch(`https://api.menu-rest.com/api/v1/order/60325/order/${orderId}/close`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setIsActive_(true);
                // document.getElementById('no').click();
            })
        } catch (error) {
            return error
        }
    }

    useEffect(() => {
        if (!token) {
            fetchAuthToken();
        }
    }, [token])

    useEffect(() => {
        getMenuList();
    }, [])

    useEffect(() => {
        if (token != null) {
            getMainMenuList();
            getTables();
        }
    }, [token])

    const handleGetRequest = (item, type) => { 
        const copy = [...request]
        if (type === 'add') {
            copy.push(item)
        } else {
            const index = copy.findIndex((element) => element.id == item.id);

            if (index !== -1) {
                copy.splice(index, 1);
            }
        }
        setRequest(copy)
    }

    const getUniqueArray = (array) => { 
        return array.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
    }

    function groupOrdersByCategory(orders) {
        // Группируем заказы по категориям
        const grouped = {1: [], 2: [], 3: [], 4: [], 5: []};
        orders?.forEach(order => {
            grouped[order.category]?.push(order);
        });
    
        // Находим максимальное количество заказов в каждой категории
        const maxLength = Math.max(grouped[1].length, grouped[2].length, grouped[3].length, grouped[4].length, grouped[5].length);
        
        // Массив для хранения результатов
        const result = [];
    
        // Собираем результат по "пакетам" заказов из разных категорий
        for (let i = 0; i < maxLength; i++) {
            const batch = [];
            
            for (let category = 1; category <= 5; category++) {
                if (grouped[category][i]) {
                    batch.push(grouped[category][i]);
                }
            }
            
            if (batch.length > 0) {
                result.push(batch);
            }
        }
    
        return result;
    }

    const handleSubmitRequest = (type) => {
        if (type === 'cancel') {
            handleCancel();
        }
        setRequest([])
        setSteps({
            isActive: 1,
            guestСount: 1,
            table: null
        })
    }


    const getGuestsErr = (guestСount) => {
        const guests_list = []
        for (let i = 0; i < guestСount; i++) {
            guests_list.push(guests)
        }
        return guests_list
    }

    const handleCreateGuests = () => {
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/order/60325/table/${steps.table}/create`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({guests: getGuestsErr(steps.guestСount)})
            })
            .then(response => response.json())
            .then(data => {
                
                groupOrdersByCategory(request).map(el => 
                el.map(_el => {
                    return {
                        orderid: data.data[0].id,
                        guest_id: 1,
                        menu_id: _el.id,
                        bl_category_id: _el.category,
                        amount: 1,
                        orderrowcomment: _el.decr,  
                        orderoptions: null
                }})
                )
                .map(el => handleAddFood(el))
            })
        } catch (error) {
            return error
        }
    }

    const handleAddFood = (data_) => {
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/order/60325/order/${data_[0].orderid}/${blList[0].bid}/1`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    data_)
            })
            .then(response => response.json())
            .then(data => {
                setOrderId(data_[0].orderid)
                handleSent(data_[0].orderid)
            })
        } catch (error) {
            return error
        }
    }

    const handleSent = (id) => {
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/order/60325/order/${id}/sent`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(response => response.json())
            .then(data => {
                // handleAddName(id)
                setSteps({...steps, isActive: steps.isActive + 1});
                setIsOpenName(false);
                setGuests('');
            })
        } catch (error) {
            return error
        }
    }

    const handleAddName = (id) => {
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/order/60325/orderguest/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({name: guests})
            })
            .then(response => response.json())
            .then(data => {
                setSteps({...steps, isActive: steps.isActive + 1});
                setIsOpenName(false);
                setGuests('');
            })
        } catch (error) {
            return error
        }
    }

    const handleOpenMenu = () => {
        setRequest([])
        setSteps({
            isActive: 1,
            guestСount: 1,
            table: null
        })
        setOpenMenu(true);
    }
    
    return (
        <div className={styles.wrapper} id='no'>
            <Timer 
                isActive={isActive}
                setIsActive={setIsActive}
                isActive_={isActive_}
                setIsActive_={setIsActive_}
                handleSubmitRequest={handleSubmitRequest} 
                setIsOpenName={setIsOpenName} 
                setGuests={setGuests} />
            <Clock/>

            <div className={styles.content}>
                    {
                        (!openMenu && steps.isActive > 1) ? <></> : <div className={styles.openMenu} onClick={() => handleOpenMenu()}>В меню&nbsp;<FiArrowRightCircle/></div>
                    }
                    {request.length > 0 && 
                     steps.isActive === 2 &&
                     steps.table !== null &&
                    <div className={styles.requestButton} onClick={() => setIsOpenName(true)}>заказать</div>}
                    {request.length > 0
                        ? <div className={styles.request}>
                            <div className={styles.requestList}>
                                {getUniqueArray(request).map((item, index) => {
                                    return (
                                        <div key={index} className={styles.requestItem}>
                                            {item.title_ru} - {request.filter(item_ => item_.id === item.id).length}
                                            <MdEdit onClick={() => handleGetAddDecription(item)}/>
                                        </div>
                                    )
                                })} 
                            </div>
                        </div>
                        : <></>}
                <Navbar/>
                <div className={styles.menu}>
                    <div className={styles.menuWrapper}>
                        {steps.isActive === 3
                            ? <Popup orderId={orderId} count={groupOrdersByCategory(request)} steps={steps} setSteps={setSteps} request={request} getUniqueArray={getUniqueArray} handleSubmitRequest={handleSubmitRequest} />
                            : <></>}

                        {isOpenDecr && 
                            <Decr 
                            setIsOpenDecr={setIsOpenDecr}
                            isOpenDecr={isOpenDecr}
                            setRequest={setRequest} 
                            request={request} 
                            handleGetRequest={handleGetRequest} 
                            handleGetAddDecription={handleGetAddDecription} /> }
                        {steps.isActive === 2 && <Tables tables={tables} setSteps={setSteps} steps={steps} /> }
                        {steps.isActive === 1 && 
                        <div className={styles.back}>
                            <Continue steps={steps} setSteps={setSteps} request={request} />    
                        </div>
                        }

                        {steps.isActive === 1 && (
                            <>
                            {
                                !openMenu ? 
                                <BusinessLunch 
                                    data={blList} 
                                    request={request} 
                                    handleGetRequest={handleGetRequest} />
                                : 
                                <FullMenu fullMenu={fullMenuList} closeMenu={() => setOpenMenu(false)}/>
                            }
                            </>
                            )
                        }

                        {
                            isOpenName &&
                            <Name 
                            guests={guests}
                            setGuests={setGuests}
                            handleCreateGuests={handleCreateGuests}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index