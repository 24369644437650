import React from 'react'
import styles from './index.module.scss'
import Continue from '../Continue'

const Index = ({
    tables = [],
    setSteps,
    steps
}) => {

    const handleSetTable = (table) => {
        if (steps.table === table.id) {
            setSteps({
                ...steps,
                table: null
            })
        } else {
            setSteps({
                ...steps,
                table: table.id
            })
        }
    }

    return (
        <div className={styles.wrapper}>

            <div className={styles.menuTitle}>
                <span>Выберите стол</span>
                {<Continue steps={steps} setSteps={setSteps}/>}
            </div>

            <div className={styles.tables}>
            <div 
            onClick={() => handleSetTable({id: 1})}
            className={`${styles.number_1}  ${steps.table == 1 ? styles.active : ''}`}></div>
            <div 
            onClick={() => handleSetTable({id: 2})}
            className={`${styles.number_2}  ${steps.table == 2 ? styles.active : ''}`}></div>
            <div 
            onClick={() => handleSetTable({id: 3})}
            className={`${styles.number_3}  ${steps.table == 3 ? styles.active : ''}`}></div>
            <div 
            onClick={() => handleSetTable({id: 4})}
            className={`${styles.number_4}  ${steps.table == 4 ? styles.active : ''}`}></div>
            <div 
            onClick={() => handleSetTable({id: 5})}
            className={`${styles.number_5}  ${steps.table == 5 ? styles.active : ''}`}></div>
            <div 
            onClick={() => handleSetTable({id: 6})}
            className={`${styles.number_6}  ${steps.table == 6 ? styles.active : ''}`}></div>
            <div 
            onClick={() => handleSetTable({id: 7})}
            className={`${styles.number_7}  ${steps.table == 7 ? styles.active : ''}`}></div>
            <div 
            onClick={() => handleSetTable({id: 12})}
            className={`${styles.number_12}  ${steps.table == 12 ? styles.active : ''}`}>
            </div>

                {/* {tables
                    ?.map((item) => {
                        return (
                            <div
                                className={`${styles.table} ${steps.table == item.id
                                ? styles.active
                                : ''}`}
                                key={item.id}
                                onClick={() => handleSetTable(item)}>
                                <div className={styles.name}>Стол: {item.name}</div>
                                <div className={styles.seats}>Кол-во мест: {item.seats}</div>
                            </div>
                        )
                    })} */}
            </div>
        </div>
    )
}

export default Index