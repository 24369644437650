import React, { useState } from 'react'
import styles from './index.module.css'
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const Index = ({setIsOpenDecr, setRequest, request, isOpenDecr}) => {

    const [layoutName, setLayoutName] = useState("default");

    // Раскладка клавиатуры с только буквами, пробелом, кнопкой Caps Lock и кнопкой Backspace
    const layouts = {
        default: [
            "й ц у к е н г ш щ з х ъ",
            "ф ы в а п р о л д ж э",
            "я ч с м и т ь б ю .",
            "{backspace} {space} {lock}"
        ],
        shift: [
            "Й Ц У К Е Н Г Ш Щ З Х Ъ",
            "Ф Ы В А П Р О Л Д Ж Э",
            "Я Ч С М И Т Ь Б Ю .",
            "{backspace} {space} {lock}"
        ]
    };

    // Обработчик изменения ввода
    const onChange = (input) => {
        handleSetDecr(input);
        console.log("Input changed", input);
    };

    // Обработчик нажатия кнопки
    const onKeyPress = (button) => {
        console.log("Button pressed", button);

        // Переключение раскладки при нажатии на Shift или Lock
        if (button === "{shift}" || button === "{lock}") handleShift();
        if (button === "{space}") handleSpace();
        if (button === "{backspace}") handleBackspace();
    };

    // Обработка нажатия пробела
    const handleSpace = () => {
        handleSetDecr((prevInput) => prevInput + " ");
    };

    // Обработка нажатия Backspace (удаление последнего символа)
    const handleBackspace = () => {
        handleSetDecr((prevInput) => prevInput.slice(0, -1));
    };

    // Переключение раскладки клавиатуры (Caps Lock)
    const handleShift = () => {
        setLayoutName((prevLayoutName) =>
            prevLayoutName === "default" ? "shift" : "default"
        );
    };

    // Обработчик изменения ввода через input
    const onChangeInput = (event) => {
        const input = event.target.value;
        handleSetDecr(input); // Обновление состояния input
    };

    const handleSetDecr = (decr) => {
        const newItem = request.map(item_ => item_.id === isOpenDecr ? {...item_, decr} : item_)
        setRequest(newItem)
    }

  return (
    <div className={styles.wrapper_bg}>
        <div className={styles.wrapper}>
            <div className={styles.title}>Комментарий для блюда <br/> "{request.find(item => item.id === isOpenDecr).title_ru}"</div>
            <textarea 
            placeholder='Введите описание'
            // onChange={(e) => handleSetDecr(e.target.value)}
            redonly
            disabled
            value={request.find(item => item.id === isOpenDecr)?.decr}
            className={styles.text}/>
        
            <Keyboard
                className={styles.keyboard}
                layout={layouts} // Используем кастомную раскладку
                layoutName={layoutName}  // Смена раскладки
                onChange={onChange}      // Обновление ввода на виртуальной клавиатуре
                onKeyPress={onKeyPress}  // Обработка нажатий клавиш
            />

            <div className={styles.buttons}>
                <div className={styles.ok} onClick={() => setIsOpenDecr(null)}>OK</div>
            </div>
        </div>
    </div>
  )
}

export default Index