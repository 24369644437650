import React, { useState } from 'react';
import styles from './index.module.css';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const Index = ({ guests, setGuests, handleCreateGuests }) => {
    
    const [layoutName, setLayoutName] = useState("default");

    // Раскладка клавиатуры с только буквами, пробелом, кнопкой Caps Lock и кнопкой Backspace
    const layouts = {
        default: [
            "Й Ц У К Е Н Г Ш Щ З Х Ъ",
            "Ф Ы В А П Р О Л Д Ж Э",
            "Я Ч С М И Т Ь Б Ю .",
            "{backspace} {space} {lock}"
        ],
        shift: [
            "й ц у к е н г ш щ з х ъ",
            "ф ы в а п р о л д ж э",
            "я ч с м и т ь б ю .",
            "{backspace} {space} {lock}"
        ]
    };

    // Обработчик изменения ввода
    const onChange = (input) => {
        if (input?.length == 1) setLayoutName("shift");
        setGuests(input);
        console.log("Input changed", input);
    };

    // Обработчик нажатия кнопки
    const onKeyPress = (button) => {
        console.log("Button pressed", button);

        // Переключение раскладки при нажатии на Shift или Lock
        if (button === "{shift}" || button === "{lock}") handleShift();
        if (button === "{space}") handleSpace();
        if (button === "{backspace}") handleBackspace();
    };

    // Обработка нажатия пробела
    const handleSpace = () => {
        setGuests((prevInput) => prevInput + " ");
    };

    // Обработка нажатия Backspace (удаление последнего символа)
    const handleBackspace = () => {
        setGuests((prevInput) => prevInput.slice(0, -1));
    };

    // Переключение раскладки клавиатуры (Caps Lock)
    const handleShift = () => {
        setLayoutName((prevLayoutName) =>
            prevLayoutName === "default" ? "shift" : "default"
        );
    };

    // Обработчик изменения ввода через input
    const onChangeInput = (event) => {
        const input = event.target.value;
        setGuests(input); // Обновление состояния input
    };

    // Обработка отправки формы
    const handleSubmit = () => {
        if (guests?.length > 0) {
            handleCreateGuests(guests);
        }
    };

    return (
        <div className={styles.name}>
            <div className={styles.nameBlock}>
                <div className={styles.nameTitle}>Введите Ваше имя</div>

                <input
                    className={styles.nameInput}
                    value={guests} // Обновление значения input
                    placeholder={""}
                    disabled
                    onChange={onChangeInput} // Обработчик изменения
                />

                <Keyboard
                    className={styles.keyboard}
                    layout={layouts} // Используем кастомную раскладку
                    layoutName={layoutName}  // Смена раскладки
                    onChange={onChange}      // Обновление ввода на виртуальной клавиатуре
                    onKeyPress={onKeyPress}  // Обработка нажатий клавиш
                />

                <div className={styles.nameButton} onClick={handleSubmit}>
                    OK
                </div>
            </div>
        </div>
    );
};

export default Index;
